.dot-flashing {
  --color-on: rgba(152, 128, 255, 0.2);
  --color-off: #9880ff;
  --dot-size: 10px;
  --border-size: calc(var(--dot-size) * 0.5);
  --space-size: calc(var(--dot-size) + var(--border-size));
  position: relative;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--border-size);
  background-color: var(--color-on);
  color: var(--color-on);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: calc(var(--space-size) * -1);
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--border-size);
  background-color: var(--color-on);
  color: var(--color-on);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: var(--space-size);
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--border-size);
  background-color: var(--color-on);
  color: var(--color-on);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--color-on);
  }
  50%, 100% {
    background-color: var(--color-off);
  }
}